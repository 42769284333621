//form
.detailed-future-card {
  width:fit-content;
  background: #f2f9ff;
  box-shadow: 0px 43px 80px 0px rgba(0, 0, 0, 0.0705882353);
  color: #064a88;
  z-index: 100;
  position: absolute;
  padding: 10px;
  border-radius: 5px;
  left: 8%;
  top: 50%;
  font-size: 12px;
  display: none;
}
.futureProjections {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow-y: auto;
  height: 25vh;
  padding: 10px;
  overflow-x: hidden;
}
.red-border {
  border-top: 5px solid red;
  border-radius: 0px 5px 5px 0px;
}

.green-border {
  border-top: 5px solid rgb(22, 202, 22);
  border-radius: 0px 5px 5px 0px;
}
.learning-coe {
  // height: 100%;
  border: 1px solid rgb(250, 250, 250);
}
.future-card {
  cursor: pointer;
  height: fit-content;
  width: 180px;
  padding: 10px;
  background-color: rgb(250, 250, 250);
  border-radius: 5px;
  display: flex;
  position: relative;
  justify-content: space-between;
  $parent: &;
  @at-root #{$parent} {
    &:hover {
      .detailed-future-card  {
       display: block;
      }
    }
  }
}
.future-card-actions {
  display: flex;
  gap: 5px;
  justify-content: space-between;
}
.edit-delete-btn {
  cursor: pointer;
}
.future-projection-content {
}
.black-svg {
  cursor: pointer;
  stroke: black;
  fill: black;
  path {
    fill: black;
  }
}
.add-new {
  color: #064173;
  font-family: Averta;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: right;
  cursor: pointer;
  margin: 10px;
  position: sticky;
}
.future-projection-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.eachFutureProjection {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  align-items: center;
}
.close {
  display: none;
}
.futureProjection-heading {
  font-size: 20px;
  line-height: 29.96px;
}
.tg-dropdown-list .tags-dropdown-list .tg-list-item{
  word-break: break-word;
}
.buzz-form-container {
  background-color: #f2f9ff;
  border-radius: 0.5rem;
  --input-shadow: 0 0 0 0.25rem #f1f1f1;
  .tg-dropdown-template {
    width: 100%;
  }
  .buzz-dropdown-template {
    .tg-dropdown-list .tg-list-item-value {
      word-wrap: break-word;
      word-break: break-word;
    }
    .tg-dropdown-value {
      vertical-align: middle;
      width: 225px;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      overflow: hidden;
    }
    @media (min-width: 600px) and (max-width: 992px) {
      .tg-dropdown-value {
        width: unset;
      }
    }
  }
  .tg-input-group .tg-input-control {
    border: none;
    background-color: white;
    overflow: hidden;
    &:has(input:disabled) {
      background-color: #ececec;
    }
    .affix-clear svg {
      fill: rgb(166, 166, 166);
    }
    input:disabled {
      opacity: 0.5;
      & + .affix {
        opacity: 0.5;
      }
    }
  }
  .tg-input-group {
    --tg-input-border: white;
    .affix {
      background-color: white;
      border-radius: 0 5px 5px 0;
    }
    input {
      font-family: inherit;
      flex-grow: 1;
      background-color: transparent;
      border: none;
      padding: 0.95rem 0.9rem;
      height: 100%;
      width: 100%;
      background-color: white !important;
      border-radius: 5px;
    }
  }

  .buzz-form-label {
    font-weight: 600px;
    font-size: 20px;
    line-height: 29.96px;
    letter-spacing: -3%;
  }
  .date-picker-wrapper .new-input-control {
    width: inherit !important;
    border: none !important;
    input {
      border-radius: 5px 0 0 5px;
    }
  }
.date-picker-wrapper .react-datepicker {
    font-family: var(--font-family) !important;
    border: 0;
    box-shadow: var(--dropdown-shadow);
    padding: 3px;

}
.date-picker-wrapper .controls-container {
    display: flex;
    flex-direction: column;
    padding: 3px;
    background-color: var(--widget-bg);
}
.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 1rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.2rem;
    display: inline-block;
    position: relative;
  }
  .date {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 0.6rem;
    padding-left: 9px;
    .required {
      color: var(--error);
      margin-left: 0.5rem;
    }
  }

  .tg-tags-input {
    .tags-input .tag-search-input {
      background-color: #ffff;
    }
  }

  .tg-tag .tg-tag-content {
    background-color: var(--primary);
    color: white;
  }

  .react-datepicker {
    font-size: 12px !important;
    input {
      border-radius: 5px 0 0 5px;
    }
  }
  .error-msg {
    .tg-dropdown-control,
    .tg-input-control,
    .new-input-control {
      border-bottom: 2px solid var(--error) !important;
    }
    .date-picker-wrapper .tg-dropdown-control,
    .date-picker-wrapper .tg-input-control {
      border-bottom: none !important;
    }
  }
  .tg-dropdown-control .tg-dropdown-value {
    font-weight: unset;
  }
  .tg-dropdown-list {
    input {
      background-color: #f2f9ff !important;
    }
  }
  .error-msg-tags-input {
    .tag-search-input {
      border-bottom: 2px solid var(--error);
    }
  }
  .error {
    padding-top: 2px;
    color: var(--error);
    font-size: 1rem;
    margin-top: 0.2rem;
    padding-left: 9px;
  }
}
.side-nav-bar-container
  .menu-items-wrapper
  .menu-items
  .not-active-class
  .menu-item-name {
  font-weight: 400;
  font-size: 12px;
}

.buzz-form-button {
  background-color: var(--primary) !important;
}

.buzz-del-button {
  border-radius: 0.3rem;
  background-color: #a6a6a6;
  color: white;
  width: 3rem;
  height: 3.6rem;
  padding: 1rem;
}
.filter-button {
  background-color: white;
  color: #064a88;
  width: 75px;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 5px;
}
.tg-overlay {
  .tg-dialog-footer {
    justify-content: space-between !important;
    .modal-cancel-button {
      background-color: #f2f9ff !important;
      color: var(--primary) !important;
    }
    .btn-primary {
      padding: 9px 18px !important;
      border-radius: 5px !important;
      font-weight: 400 !important;
      font-size: 10px !important;
    }
  }
}
.vertical-timeline-container {
  height: 43vh !important;
}
.error-msg-datepicker {
  .react-datepicker-wrapper {
    .new-input-control {
      border-bottom: 2px solid var(--error) !important;
    }
  }
}
.react-datepicker-wrapper{
  display: flex;
}
html {
  &:has(.fade) {
    .tg-overlay {
      z-index: 1056;
    }
  }
}
.mobile-menu-wrapper .mobile-menu-bar {
  &:has(.buzz-dropdown-list) {
    .buzz-dropdown-list div svg path {
      fill: #ff5f5f;
    }
  }
}
.buzz-dropdown-list {
  cursor: pointer;
  padding: 0px 20px;
  &:hover {
    color: var(--primary);
    background-color: var(--lite);
  }
}
//grid
.tg-grid-search-inline-message {
  color: var(--primary);
  padding-left: 0.5rem !important;
  font-size: 1.1rem;
}
.tg-grid-container .tg-grid-header {
  padding-right: 0px;
}

.nav-tab + .contacts-container {
  .tg-grid-container .tg-grid-header {
    padding-top: 0px;
  }
}
.view-button {
  display: flex;
  gap: 1rem;
}
.grid-action-button {
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 8px 18px;
  &.InActive {
    background-color: white;
    color: var(--primary);
  }
}
.grid-action-button span {
  vertical-align: bottom;
  padding-left: 5px;
}
.bulk-action-button {
  margin-left: 10px;
  background-color: #ffffff;
  color: var(--primary);
  font-weight: 600;
}
.tg-grid-pagination-numbers .active {
  background-color: var(--primary) !important;
  color: #ffffff;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background-color: var(--primary);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--secondary);
  border-radius: 30px;
}

.tg-dialog {
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.tg-input-group .tg-input-control .affix svg {
  width: 1.2rem;
  height: 1.2rem;
}

.grid-column-name {
  font-weight: bold;
  color: #121212;
}

//360Page
.nav-tab {
  position: sticky;
  z-index: 990;
  background-color: #f3f3f3;
  top: calc(var(--d-mobile-header-height) + var(--d-mobile-body-padding));
  @media (min-width: 768px) {
    top: calc(var(--d-header-height) + 1rem);
  }

  .overflow-wrapper.horizontal {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .tg-tabs .tg-tab-list li a:hover::before {
    background-color: transparent;
  }
  .tg-tabs .tg-tab-list li.active > a:hover::before,
  .tg-tabs .tg-tab-list li > a.active:hover::before {
    background-color: var(--primary);
  }

  .tg-tabs {
    margin-bottom: 0px;

    .tg-tab-list li > a.hover::before {
      background-color: transparent;
    }
    .overflow-wrapper .horizontal {
      margin-bottom: 1rem;
    }
  }
}
.accountOwnersCell {
  display: flex;
  flex-wrap: nowrap;
  top: 5px;
  padding-top: 5px;
}

//overview
.buzz-overview {
  // 1em = 14px, 1.0714em = 15px
  margin: 20px 0 0 0 !important;
  .left-wrapper {
    height: 100%;
    position: relative;
    margin-bottom: 10px;
    padding: 0px !important;

    @media (min-width: 768px) {
      position: sticky;
      top: calc(4rem + 49px + var(--d-header-height));
      margin-bottom: 0px;
      padding: 0 1.5rem 0 0 !important;
    }
  }
  .buzz-profile-data {
    table {
      table-layout: fixed;
      width: calc(100% + 3rem);
    }
    table .data-value {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .data-value {
      color: #6c6969;
    }
  }
  .project-type-value {
    color: var(--primary);
  }
  .project-status-value {
    color: green;
    &.Inactive {
      color: red;
    }
  }
  width: 100%;
  .details-wrapper {
    .tg-card {
      height: 100% !important;
    }
  }
  .active {
    color: var(--active);
  }
  .on {
    //on hold
    color: var(--on);
  }
  .inactive {
    color: var(--inactive);
  }
  a {
    word-wrap: break-word;
    text-decoration: none;
    color: var(--primary);
    padding: 0;
  }
  .buzz-profile-data {
    gap: 20%;
    margin-top: 2rem;
    white-space: nowrap;
    width: 90%;
    .buzz-data {
      color: #6c6969;
    }

    .buzz-code-value {
      color: var(--primary);
      text-decoration: underline;
    }
  }
  .buzz-overview-profile {
    background-color: #f2f9ff;
    width: 90%;
    margin: auto;
  }
  .wrap-buzz-blocks {
    display: flex;
    flex-direction: column;

    .add-button-wrapper .tg-card {
      margin-bottom: 0px !important;
      height: 100%;
    }
    .buzz-overview-text {
      font-size: 12px;
      line-height: 22.56px;
      text-align: center;
      color: #6c6969;
      .buzz-overview-text-heading {
        font-weight: 600;
      }
      .buzz-overview-text-content {
        font-weight: 400;
      }
    }
  }

  .line-seperator {
    width: 80%;
    border-bottom: 1px dotted var(--primary);
    margin-left: 10%;
  }

  .buzz-link {
    word-wrap: break-word;
    padding: 30px;
    color: var(--primary);
    a {
      text-decoration: none;
    }
  }

  .image-wrapper {
    img {
      width: auto;
      max-width: 100%;
      height: auto;
    }
  }

  .buzz-overview-add-button {
    margin-top: 8px;
    background-color: var(--primary);
    color: white;
    border: none;
    font-family: 'Averta';
    border-radius: 4px;
    padding: 9px 18px 9px 18px;
  }
}

.tg-dropdown-template:focus {
  .dropdown-wrapper {
    .tg-dropdown-control {
      border-width: 1px;
      box-shadow: var(--input-shadow) !important;
    }
  }
}

.dropdown-wrapper .active {
  .tg-dropdown-control {
    border-width: 1px;
    box-shadow: var(--input-shadow) !important;
  }
}
.tg-dropdown-control {
  justify-content: space-between;
  background-color: white;
}
.tg-grid-header-search {
  input::placeholder {
    font-size: 12px;
  }
}
.date-picker-wrapper .react-datepicker {

  font-family: var(--font-family) !important;

  border: 0;

  box-shadow: var(--dropdown-shadow);

  padding: 3px;



}

.date-picker-wrapper .controls-container {

  display: flex;

  flex-direction: column;

  padding: 3px;

  background-color: var(--widget-bg);

}
.tableheadings {
  border: 1px solid black;
  border-collapse: collapse;
}
.react-datepicker {

  font-family: "Helvetica Neue", helvetica, arial, sans-serif;

  font-size: 1rem;

  background-color: #fff;

  color: #000;

  border: 1px solid #aeaeae;

  border-radius: 0.2rem;

  display: inline-block;

  position: relative;

}
.tg-header-wrapper {
  .search {
    label {
      background-color: #f2f9ff !important;
      input {
        &::placeholder {
          color: #064a88;
        }
      }
      svg {
        path {
          stroke: #064a88;
        }
      }
    }
  }
}
.dorpdown-list.expanded {
  // width: 100%;
}
.buzz-profile-data{
  padding-left: 1.5rem!important;
}

.occupiedDiv{
  border: 1px solid #efe9e9;
  border-radius: 7px;
  padding: 5px;
  margin: 10px;
  color: var(--control-accent);
  font-weight: bold;
  // accent-color: var(--control-accent);
}

.tg-overlay .tg-dialog-content
{
  overflow-x: hidden;
}
.future.d-flex.flex-column.p-4.h-100 {
  height: inherit !important;
}
.account-landing-container, .project-landing-container{
  tr{
    cursor: pointer !important;
  }
}
tr{
  cursor: default !important;
}
svg{
  cursor: pointer;
}